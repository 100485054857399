import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import { firebaseConfig as firebaseConfigEnvs } from './constants';

const firebaseConfig = firebaseConfigEnvs;

const app = initializeApp(firebaseConfig);
// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA as string),

//     // Optional argument. If true, the SDK automatically refreshes App Check
//     // tokens as needed.
//     isTokenAutoRefreshEnabled: true
//   });

export const auth = getAuth(app);
export const db = getFirestore(app);
