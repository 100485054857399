export const getParametersFromUrl = (parametersInUrl: string) => {
  const doesUrlHaveParameters = parametersInUrl.includes('?');

  if (parametersInUrl && doesUrlHaveParameters) {
    const parameters: { [key: string]: any } = {};

    parametersInUrl
      .split('?')[1]
      .split('&')
      .forEach((parametersInUrl) => {
        const parameterKeyValuePairs = parametersInUrl.split('=');

        Object.assign(parameters, { [parameterKeyValuePairs[0]]: parameterKeyValuePairs[1] });
      });

    return parameters;
  }

  return {};
};

export const delayed = (action: () => void, delay: number) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        action();
      } catch (err) {
        reject();
      } finally {
        resolve(null);
      }
    }, delay);
  });

export const getAuthErrorMessage = (errorCode: string) => {
  const errorMessagesSupported = [
    'auth/email-already-in-use',
    'auth/wrong-password',
    'auth/too-many-requests',
    'auth/user-not-found',
  ];

  if (errorMessagesSupported.includes(errorCode)) {
    return errorCode;
  }

  return 'generic';
};

export const formatDate = (date: Date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mese è zero-based, quindi aggiungi 1
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day}/${month}/${year}, ${hours}:${minutes}`;
};
