export const environments = {
  DEV: 'DEV',
  PROD: 'PROD',
};

export const currentEnv = process.env.REACT_APP_ENV ?? 'DEV';
export const rpcProvider = process.env.REACT_APP_RPC_PROVIDER;

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messaggingSenderId: process.env.REACT_APP_MESSAGGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

export const textColors = {
  'primary-100': 'text-primary-100',
  'primary-400': 'text-primary-400',
  'primary-500': 'text-primary-500',
  'secondary-100': 'text-secondary-100',
  'secondary-500': 'text-secondary-500',
  'secondary-800': 'text-secondary-800',
  transparent: 'text-transparent',
  'grey-50': 'text-grey-50',
  'grey-100': 'text-grey-100',
  'grey-200': 'text-grey-200',
  'grey-300': 'text-grey-300',
  'grey-400': 'text-grey-400',
  'grey-500': 'text-grey-500',
  'grey-600': 'text-grey-600',
  success: 'text-success',
  warning: 'text-warning',
  error: 'text-error',
  white: 'text-white',
  black: 'text-black',
  inherit: 'text-inherit',
};

export const fillColors = {
  'primary-100': 'fill-primary-100',
  'primary-400': 'fill-primary-400',
  'primary-500': 'fill-primary-500',
  'secondary-100': 'fill-secondary-100',
  'secondary-500': 'fill-secondary-500',
  'secondary-800': 'fill-secondary-800',
  transparent: 'fill-transparent',
  'grey-50': 'fill-grey-50',
  'grey-100': 'fill-grey-100',
  'grey-200': 'fill-grey-200',
  'grey-300': 'fill-grey-300',
  'grey-400': 'fill-grey-400',
  'grey-500': 'fill-grey-500',
  'grey-600': 'fill-grey-600',
  success: 'fill-success',
  warning: 'fill-warning',
  error: 'fill-error',
  white: 'fill-white',
  black: 'fill-black',
  inherit: 'fill-inherit',
};
