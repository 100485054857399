import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { signOut } from 'firebase/auth';
import { useQuery } from 'react-query';
import { IconBrand } from '../assets/icons';
import { ImageBack, ImageLogout } from '../assets/images';

import { Button, Image, Typography } from '../components';

import { FieldsStep, ScanStep, WelcomeStep } from '.';
import { fetchNfts } from '../api/fetchNfts';
import { AuthContext } from '../contexts/AuthContext';
import { auth } from '../imports/firebase';
import { formatDate } from '../imports/utils';

enum Steps {
  LOGIN,
  SELECT_TOKEN,
  SCAN,
  RESULT,
}

export interface ScanResult {
  status: 'owner' | 'not-owner' | 'time-elapsed';
  numberOfVerifications?: number;
  history?: { date: string }[];
  isValid?: boolean | undefined;
}

interface DefaultValues {
  tokenId: string;
  contractId: string;
}

interface Token {
  tokenId: string;
  contractAddress: string;
}

const Home = () => {
  const { t } = useTranslation();
  const [step, setStep] = useState<Steps>(Steps.LOGIN);
  const [scanResult, setScanResult] = useState<ScanResult>();
  const [defaultValues, setDefaultValues] = useState<DefaultValues>();
  const [nft, setNft] = useState<Token>();
  const { user } = useContext(AuthContext);

  const {
    data: nfts,
    isLoading: loading,
    error,
  } = useQuery(['fetch-nfts', user?.uid], () => fetchNfts(user?.uid!), {
    enabled: user?.uid != null,
  });

  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const background = !scanResult?.status
    ? 'bg-black'
    : scanResult.status === 'owner'
    ? 'bg-status-owner'
    : 'bg-status-not-owner';

  const onVerificationCompleted = (verificationResults: ScanResult) => {
    setScanResult(verificationResults);
    setStep(Steps.RESULT);
  };

  const onExitResult = () => {
    setStep(Steps.SCAN);
    setScanResult(undefined);
  };

  const renderStep = (step: Steps) => {
    switch (step) {
      case Steps.LOGIN:
        return <WelcomeStep nextStep={() => setStep(Steps.SELECT_TOKEN)} />;
      case Steps.SELECT_TOKEN:
        return (
          <FieldsStep
            contracts={nfts}
            loading={loading}
            nextStep={() => setStep(Steps.SCAN)}
            defaultValues={defaultValues}
            onSubmit={(contractAddress: string, tokenId: string) => {
              setNft({ contractAddress, tokenId });
              const contractId = nfts?.find(
                (nft) => nft.contractAddress === contractAddress
              )?.contractId;
              if (contractId != null) {
                setDefaultValues({ contractId, tokenId });
              }
            }}
          />
        );
      case Steps.SCAN:
        return (
          <>
            <div className="fixed top-0 flex h-[4.68rem] w-full items-center bg-primary-500 px-4">
              <button type="button" onClick={prevStep}>
                <Image src={ImageBack} alt={t('go_back')} className="h-8 w-8" />
              </button>
            </div>
            <ScanStep
              // prevStep={prevStep}
              contractAddress={nft!.contractAddress}
              tokenId={nft!.tokenId}
              onVerificationCompleted={onVerificationCompleted}
            />
          </>
        );
      case Steps.RESULT:
        return (
          <>
            <Typography as="h2" size="2xl" className="text-center">
              {t(
                scanResult?.status === 'owner'
                  ? 'status_owner'
                  : scanResult?.status === 'time-elapsed'
                  ? 'time_elapsed'
                  : 'status_not_owner'
              )}
            </Typography>

            {scanResult?.status === 'owner' && scanResult?.isValid !== undefined && (
              <Typography as="p" size="sm">
                {scanResult.isValid ? t('expired_false') : t('expired_false')}
              </Typography>
            )}

            {!Number.isNaN(scanResult?.numberOfVerifications) && scanResult?.status === 'owner' && (
              <Typography as="p" size="sm">
                {t('certified', { number: scanResult?.numberOfVerifications })}
              </Typography>
            )}

            <div className="mx-6 my-8 max-h-48 overflow-y-auto">
              {scanResult?.history &&
                scanResult?.history.map(({ date }, i) => (
                  <div key={i}>{formatDate(new Date(date))}</div>
                ))}
            </div>

            <div>
              <Button action={onExitResult} type="ghost">
                {t('close')}
              </Button>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const contractId = url.searchParams.get('contractId');
    const tokenId = url.searchParams.get('tokenId');
    if (contractId != null && tokenId != null) {
      setDefaultValues({ contractId, tokenId });
    }
  }, []);

  return (
    <section className={`h-full w-full ${background} flex flex-col justify-between bg-black `}>
      {step !== Steps.LOGIN && (
        <button
          type="button"
          onClick={() => {
            signOut(auth);
            window.location.reload();
          }}
          className="hover:bg-blue fixed right-3 top-3 flex h-20 w-20 cursor-pointer flex-col items-center justify-center truncate rounded-lg px-1"
        >
          <Image
            src={ImageLogout}
            alt={t('sidebar.logout')}
            className="h-[1.875rem] w-[1.875rem] shrink-0"
          />
          <Typography className="pt-1 text-center text-xs">{t('logout')}</Typography>
        </button>
      )}
      <div className="flex min-h-[calc(100vh-5rem)] flex-col items-center justify-center pt-4  text-white">
        {/* Step based on state: step */}
        {renderStep(step)}
        {/* ------------------ */}
      </div>
      <div
        className={`flex w-full flex-col items-center justify-center px-[32px] py-[8px] ${
          scanResult?.status === 'not-owner' ? 'bg-status-not-owner' : 'bg-grey-200'
        }`}
      >
        <IconBrand className="w-44" />
      </div>
    </section>
  );
};

export default Home;
