import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Contract, Nft } from '../api/fetchNfts';
import { IconLoading } from '../assets/icons';
import { Button, Field, Form, Select, Typography } from '../components';

type FieldsStepProps = {
  nextStep: () => void;
  onSubmit: (contractAddress: string, tokenId: string) => void;
  contracts?: Contract[];
  loading: boolean;
  defaultValues?: { contractId: string; tokenId: string };
};

const FieldsStep = ({ nextStep, onSubmit, contracts, loading, defaultValues }: FieldsStepProps) => {
  const { t } = useTranslation();

  const getContractOptions = () => {
    const copyContracts = [...(contracts ?? [])];
    const contractSortedByName = copyContracts.sort((a, b) =>
      a.contractName.localeCompare(b.contractName)
    );
    const optionsContracts = contractSortedByName?.map(({ contractName, contractAddress }) => ({
      id: contractAddress,
      value: contractAddress,
      label: contractName,
    }));

    // add initial disabled label
    return [
      {
        id: '',
        value: '',
        label: t('steps.fields.form_placeholders.contract_select'),
        disabled: true,
      },
      ...optionsContracts,
    ];
  };

  const options = getContractOptions();

  const fieldsForm = {
    initialValues: {
      contractAddress:
        options?.find((option) => option.label === defaultValues?.contractId)?.id ?? '',
      tokenId: defaultValues?.tokenId ?? '',
    },
    validationSchema: yup.object({
      contractAddress: yup.string().required(t('form_validation.required')),
      tokenId: yup.string().required(t('form_validation.required')),
    }),
  };

  const { initialValues, validationSchema } = fieldsForm;

  const handleNextStep = async (values: { contractAddress: string; tokenId: string }) => {
    const { contractAddress, tokenId } = values;
    onSubmit(contractAddress, tokenId);
    nextStep();
  };

  if (loading) {
    return <IconLoading className="h-12 w-12 animate-spin text-white" />;
  }

  if ((!loading && contracts == null) || !contracts?.length) {
    return <>No Nfts</>;
  }

  return (
    <div className="w-72">
      <Form
        initialValues={initialValues}
        validationSchema={validationSchema}
        className="flex w-full flex-col space-y-24"
      >
        {({ fields, handleSubmit, errors, watch }) => {
          const getNftOptions = () => {
            const contractSelected = contracts!.find(
              ({ contractAddress }) => contractAddress === watch('contractAddress')
            );
            const selectedNfts = contractSelected?.nfts;

            if (!selectedNfts) {
              return [];
            }

            const mapped = selectedNfts.map((nft: Nft) => ({
              id: nft.nftId,
              value: nft.nftId,
              label: nft.nftName,
            }));

            return [
              {
                id: '',
                value: '',
                label: t('steps.fields.form_placeholders.token_select'),
                disabled: true,
              },
              ...mapped,
            ];
          };

          const nftOptions = getNftOptions();

          return (
            <>
              <Typography as="h1" size="2xl" className="text-center">
                {t('steps.fields.title')}
              </Typography>

              <div>
                <Field
                  error={errors.contractAddress?.message}
                  label={t('steps.fields.form_placeholders.contract_address')}
                >
                  <Select
                    options={options || []}
                    id={fields.contractAddress.name}
                    name={fields.contractAddress.name}
                    inputRef={fields.contractAddress.ref}
                    onBlur={fields.contractAddress.onBlur}
                    onChange={fields.contractAddress.onChange}
                  />
                </Field>

                {watch('contractAddress') && (
                  <Field
                    error={errors.tokenId?.message}
                    label={t('steps.fields.form_placeholders.token_id')}
                  >
                    <Select
                      defaultValue={defaultValues?.tokenId}
                      options={nftOptions}
                      id={fields.tokenId.name}
                      name={fields.tokenId.name}
                      inputRef={fields.tokenId.ref}
                      onBlur={fields.tokenId.onBlur}
                      onChange={fields.tokenId.onChange}
                    />
                  </Field>
                )}
              </div>

              <Button action={handleSubmit(handleNextStep)}>{t('steps.fields.action')}</Button>
            </>
          );
        }}
      </Form>
    </div>
  );
};

export default FieldsStep;
