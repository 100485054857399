import type { ReactNode } from 'react';
import { createElement } from 'react';

import { textColors } from '../../imports/constants';
import type { Colors } from '../../imports/types';

type TypographyProps = {
  children: ReactNode;
  className?: string;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p';
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  weight?: 'regular' | 'medium' | 'semibold' | 'bold';
  family?: 'regular' | 'italic';
  color?: Colors;
};

const Typography = ({
  children,
  className = '',
  as = 'p',
  size = 'md',
  weight = 'regular',
  family = 'regular',
  color = 'inherit',
}: TypographyProps) => {
  const getTypographySize = () => {
    const typographySize: { [key: string]: string } = {
      xs: 'text-xs',
      sm: 'text-sm',
      md: 'text-md',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
    };
    return typographySize[size];
  };

  const getTypographyWeight = () => {
    const typographyWeight: { [key: string]: string } = {
      regular: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
    };
    return typographyWeight[weight];
  };

  const getTypographyFamily = () => {
    const typographyFamily: { [key: string]: string } = {
      regular: 'not-italic',
      italic: 'italic',
    };
    return typographyFamily[family];
  };

  const getTypographyColor = () => textColors[color];

  const typographyStyle = `${getTypographyFamily()} ${getTypographySize()} ${getTypographyWeight()} ${getTypographyColor()} ${className}`;

  return createElement(as, { className: typographyStyle }, children);
};

export default Typography;
