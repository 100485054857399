import './image.css';

type ImageProps = {
  src?: string;
  alt: string;
  type?: 'contain' | 'cover';
  className?: string;
};
const Image = ({ src, alt, type, className }: ImageProps) => (
  <img className={`image ${type} ${className}`} src={src} alt={alt} />
);

export default Image;
