import { QrScanner } from '@yudiel/react-qr-scanner';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IconLoading } from '../assets/icons';
import { Typography } from '../components';
import useVerify from '../hooks/useVerify';
import { getParametersFromUrl } from '../imports/utils';
import { ScanResult } from './Home';

type ScanStepProps = {
  contractAddress: string;
  tokenId: string;
  // prevStep: () => void;
  onVerificationCompleted: (verificationResults: ScanResult) => void;
};

const emptyComponent = () => <></>;

const ScanStep = ({ contractAddress, tokenId, onVerificationCompleted }: ScanStepProps) => {
  const { t } = useTranslation();

  const { verify, isLoading } = useVerify();

  const handleError = (error: any) => {
    if (error.toString() === 'NotAllowedError: Permission denied') {
      toast.error(t('steps.scan.qr_code_scanner_permissions_error'), {
        toastId: 'permissions-error',
      });
    }
  };

  const handleScan = async (text: any) => {
    if (text) {
      //const { text } = data;
      const { signature, seconds } = getParametersFromUrl(text);
      const contractId = text.split('?')[0].split('/').at(-1);

      if (signature && !isLoading) {
        try {
          const verifyResults = await verify(
            signature,
            seconds,
            contractId,
            tokenId,
            contractAddress
          );

          onVerificationCompleted(verifyResults);
        } catch (error) {
          // eslint-disable-next-line
          alert(JSON.stringify(error));
          toast.error(t('errors.generic') as string);
        }
      }
    }
  };

  return isLoading ? (
    <div className="flex h-full w-full flex-col items-center justify-center p-5">
      <IconLoading className="h-12 w-12 animate-spin text-white" />
      <Typography as="h2" size="lg" color="white" className="mt-8 text-center">
        {t('steps.scan.loading')}
      </Typography>
    </div>
  ) : (
    <div className="fixed top-[4.68rem] left-0 h-[calc(100vh-4.87rem-4.68rem)] w-screen bg-black">
      <>
        <div className="absolute top-10 z-10 w-full px-4">
          <Typography as="h1" className="text-center text-[1.37rem]">
            {t('steps.scan.frame_qr_code')}
          </Typography>
        </div>
        <QrScanner
          scanDelay={400}
          onError={handleError}
          onDecode={handleScan}
          constraints={{ facingMode: 'environment' }}
          containerStyle={{ height: '100%' }}
          videoStyle={{ height: '100%', objectFit: 'cover' }}
          viewFinderBorder={0}
          viewFinder={emptyComponent}
          //className="h-full w-full object-cover"
        />
        <div
          className="absolute top-1/2 left-1/2 h-72 w-72 -translate-x-1/2 -translate-y-1/2 rounded-2xl"
          style={{ boxShadow: '0 0 0 1000px rgba(0, 0, 0, 0.3)' }}
        />
      </>
    </div>
  );
};

export default ScanStep;
