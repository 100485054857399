import { ethers } from 'ethers';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import forge from 'node-forge';
import { decryptWallet, encryptWallet } from '../imports/crypto.utils';
import { auth, db } from '../imports/firebase';

export const hashPassword = (password: string, sha: 'sha1' | 'sha256' = 'sha1') => {
  const md = forge.md[sha].create();

  md.update(password);
  const hex = md.digest().toHex();

  return hex;
};

export const result = ({ value, error }: { value?: any; error?: any }) => ({ value, error });

export const signIn = ({ email, password }: { email: string; password: string }) => {
  const hashedPassword = hashPassword(password, 'sha1');
  const hashedWalletPassword = hashPassword(password, 'sha256');

  return signInWithEmailAndPassword(auth, email, hashedPassword)
    .then(async ({ user }) => {
      const docRef = doc(db, 'users', user.uid);
      const docSnapshot = await getDoc(docRef);

      const { emailVerified } = user;
      let profile;

      if (docSnapshot.exists()) {
        profile = docSnapshot.data();
      }

      let wallet;

      try {
        wallet = decryptWallet(profile?.wallet, hashedWalletPassword);
      } catch (err) {
        wallet = await ethers.Wallet.fromEncryptedJson(profile?.wallet, hashedPassword);
        const newWallet = encryptWallet(wallet, hashedWalletPassword);
        await setDoc(
          doc(db, 'users', user.uid),
          {
            wallet: newWallet,
          },
          { merge: true }
        );
      }

      // if (!user.emailVerified) {
      //   await sendEmailVerification(user);
      // }

      return result({
        value: {
          profile: {
            ...profile,
            wallet: {
              address: wallet?.address,
              privateKey: wallet?.privateKey,
              mnemonic: wallet?.mnemonic.phrase,
            },
            isEmailVerified: emailVerified,
          },
        },
      });
    })
    .catch((error) => {
      return result({ error });
    });
};
