import type { FunctionComponent, SVGProps } from 'react';

import { fillColors } from '../../imports/constants';
import type { Colors } from '../../imports/types';

import { IconLoading } from '../../assets/icons';

import './icon.css';

type IconProps = {
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  size?: 'sm' | 'md' | 'lg';
  color?: Colors;
  loading?: boolean;
};

const Icon = ({ icon, size = 'sm', color = 'grey-400', loading }: IconProps) => {
  const IconComponent = icon;

  const getIconColor = () => fillColors[color];

  return loading ? (
    <IconLoading className={`icon loading ${size}`} />
  ) : (
    <>{IconComponent && <IconComponent className={`icon ${getIconColor()} ${size}`} />}</>
  );
};

export default Icon;
