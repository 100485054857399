import { createCipheriv, createDecipheriv, randomBytes } from 'crypto-browserify';

import { ethers } from 'ethers';

export const symmetricDecryptData = (rawData: any, hashedPassword: any) => {
  const iv = rawData.split('.')[0];
  const data = rawData.split('.')[1];

  const decipher = createDecipheriv(
    'aes-256-ctr',
    Buffer.from(hashedPassword, 'hex'),
    Buffer.from(iv, 'hex')
  );

  let decryptedData = decipher.update(data, 'hex', 'utf-8');
  decryptedData += decipher.final();

  return decryptedData;
};

export const symmetricEncryptData = (data: any, hashedPassword: any) => {
  const iv = randomBytes(16);
  const cipher = createCipheriv('aes-256-ctr', Buffer.from(hashedPassword, 'hex'), iv);

  let encryptedData = cipher.update(data, 'utf-8', 'hex');
  encryptedData += cipher.final('hex');
  encryptedData = `${iv.toString('hex')}.${encryptedData}`;

  return encryptedData;
};

export const encryptWallet = (wallet: ethers.Wallet, hashedPassword: string) =>
  symmetricEncryptData(
    JSON.stringify({
      privateKey: wallet?.privateKey.substring(2),
      mnemonic: wallet?.mnemonic.phrase,
      address: wallet?.address,
      publicKey: wallet?.publicKey.substring(2),
    }),
    hashedPassword
  );
export const decryptWallet = (encryptedWallet: string, hashedPassword: string) => {
  const decryptedWallet = JSON.parse(symmetricDecryptData(encryptedWallet, hashedPassword));
  return ethers.Wallet.fromMnemonic(decryptedWallet.mnemonic);
};
