import type { FunctionComponent, ReactNode, SVGProps } from 'react';
import type { Colors } from '../../imports/types';

import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

import './button.css';

type ButtonProps = {
  action: () => void;
  children?: ReactNode;
  className?: string;
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>;
  iconSize?: 'sm' | 'md' | 'lg';
  iconColor?: Colors;
  type?: 'primary' | 'secondary' | 'ghost';
  buttonType?: 'button' | 'submit' | 'reset';
  loading?: boolean;
  disabled?: boolean;
};

const Button = ({
  action,
  children,
  className = '',
  icon,
  iconSize = 'md',
  iconColor,
  type = 'primary',
  loading,
  disabled,
  buttonType = 'button',
}: ButtonProps) => {
  const isIcon = !children && icon;
  const isDisabled = disabled || loading;
  const buttonTypeValue = isDisabled ? 'button' : buttonType;

  return (
    <button
      type={buttonTypeValue} //eslint-disable-line
      className={`btn ${type} ${isDisabled ? 'disabled' : ''} ${isIcon ? 'icon' : ''} ${className}`}
      onClick={action}
      disabled={isDisabled}
    >
      {isIcon ? (
        <Icon icon={icon} size={iconSize} color={iconColor} loading={loading} />
      ) : (
        <>
          {loading && <Icon size={iconSize} color={iconColor} loading />}
          {icon && !loading && <Icon icon={icon} size={iconSize} color={iconColor} />}

          <Typography weight="bold">{children}</Typography>
        </>
      )}
    </button>
  );
};

export default Button;
