import type { ReactNode } from 'react';

import Typography from '../typography/Typography';

type FieldProps = {
  children: ReactNode;
  label?: string;
  labelElementRight?: ReactNode;
  error?: string;
};

const Field = ({ children, label, labelElementRight, error }: FieldProps) => (
  <div className="flex flex-col">
    {label && (
      <div className="flex items-center justify-between">
        <Typography>{label}</Typography>
        {labelElementRight}
      </div>
    )}

    <div className="mt-2">{children}</div>

    <div className="mt-1.5 h-4">
      {error && (
        <Typography size="xs" color="error">
          {error}
        </Typography>
      )}
    </div>
  </div>
);

export default Field;
