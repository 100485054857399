import { collection, doc, getDoc, getDocs, orderBy, query, where } from 'firebase/firestore';
import { db } from '../imports/firebase';

export interface Contract {
  contractId: string;
  contractAddress: string;
  contractName: string;
  numberOfTokens: number;
  nfts: any;
}

export interface Nft {
  nftId: string;
  nftName: string;
}

export const fetchNfts = async (uid: string) => {
  const userSnapshot = await getDoc(doc(db, `users/${uid}`));
  const userAddress = userSnapshot.data()?.address;
  const ownedNftSnapshot = await getDocs(
    query(
      collection(db, 'contracts'),
      where('owner', '==', userAddress),
      //where('workspace_id', '!=', 'UNKNOWN'),
      orderBy('createdAt', 'desc')
    )
  );

  const filteredSnaps = ownedNftSnapshot.docs.filter(
    (snap) => snap.data().workspace_id !== 'UNKNOWN'
  );

  const ownedNfts = filteredSnaps.map((snap) => {
    const contractId = snap.id;
    const numberOfTokens = snap.data().maxSupplyPerRarity.length - 1;
    const contractAddress = snap.data().address;
    const contractName = snap.data().name;

    return {
      contractId,
      numberOfTokens,
      contractAddress,
      contractName,
    } as Contract;
  });

  ownedNfts.forEach(async (contract, index) => {
    const nfts = (await getDocs(collection(db, `contracts/${contract.contractId}/nfts`))).docs.map(
      (doc) => ({ nftId: doc.data().id, nftName: doc.data().name } as Nft)
    );
    contract.nfts = nfts;
  });

  return ownedNfts;
};
