import { IconAngleDown } from '../../assets/icons';

import type { InputType } from '../../imports/types';

import Icon from '../icon/Icon';

import './select.css';

type SelectProps = InputType & {
  options: {
    id: string;
    value: string;
    label: string;
    disabled?: boolean;
  }[];
};
const Select = ({
  id,
  name,
  value,
  disabled,
  error,
  success,
  onBlur,
  onChange,
  inputRef,
  defaultValue,
  options,
}: SelectProps) => {
  const getSelectStatus = () => {
    let status = '';

    if (error) {
      status = 'error';
    }
    if (success) {
      status = 'success';
    }
    if (disabled) {
      status = 'disabled';
    }

    return status;
  };

  return (
    <div className="select-container">
      <select
        className={`select ${getSelectStatus()}`}
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        onChange={onChange}
        defaultValue={defaultValue || options?.[0]?.id}
        ref={inputRef}
      >
        {options.map((option, i) => {
          const isPlaceholder = option.disabled;

          return (
            <option key={i} value={option.value} disabled={isPlaceholder} hidden={isPlaceholder}>
              {option.label}
            </option>
          );
        })}
      </select>

      <div className="icon-container">
        <Icon icon={IconAngleDown} size="md" />
      </div>
    </div>
  );
};

export default Select;
