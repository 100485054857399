import { useContext, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { signIn } from '../api/firebase';
import { IconEyeClose, IconEyeOpen, IconLoading } from '../assets/icons';
import { Button, Field, Form, Input, Typography } from '../components';
import { AuthContext } from '../contexts/AuthContext';
import useValidation from '../hooks/useValidation';
import { getAuthErrorMessage } from '../imports/utils';

type WelcomeStepProps = {
  nextStep: () => void;
};

const WelcomeStep = ({ nextStep }: WelcomeStepProps) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [showInputPassword, setShowInputPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const { required, validateEmail, validatePassword } = useValidation();
  const form = {
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: object({
      email: required(validateEmail()),
      password: required(string()),
    }),
  };

  const onSubmit: SubmitHandler<typeof form['initialValues']> = async ({ email, password }) => {
    setLoading(true);
    const { error } = await signIn({ email, password });

    setLoading(false);
    if (error) {
      toast.error(t(`authentication.errors.${getAuthErrorMessage(error.code)}`) as string);
      setLoading(false);
    }
  };

  if (user) {
    nextStep();

    return <></>;
  }

  if (user === undefined) {
    return <IconLoading className="h-12 w-12 animate-spin text-white" />;
  }

  return (
    <div className="w-72">
      <Typography as="h1" size="2xl" className="text-center">
        {t('steps.initial.title')}
      </Typography>
      <Typography as="h2" className="mt-20 text-center">
        {t('steps.initial.subtitle')}
      </Typography>
      <Form
        initialValues={form.initialValues}
        validationSchema={form.validationSchema}
        className="mt-[64px]"
      >
        {({ errors, handleSubmit, fields }) => (
          <>
            <Field error={errors.email?.message} label="Email">
              <Input
                placeholder="Email"
                error={errors.email?.message}
                name={fields.email.name}
                onChange={fields.email.onChange}
                onBlur={fields.email.onBlur}
                inputRef={fields.email.ref}
                id={fields.email.name}
              />
            </Field>

            <Field error={errors.password?.message} label="Password">
              <Input
                type={showInputPassword ? 'text' : 'password'}
                placeholder="Password"
                elementRight={
                  <Button
                    type="ghost"
                    icon={showInputPassword ? IconEyeClose : IconEyeOpen}
                    action={() => setShowInputPassword(!showInputPassword)}
                  />
                }
                error={errors.password?.message}
                name={fields.password.name}
                onChange={fields.password.onChange}
                onBlur={fields.password.onBlur}
                inputRef={fields.password.ref}
              />
            </Field>

            <Button
              action={handleSubmit(onSubmit)}
              className="mt-24"
              loading={loading}
              buttonType="submit"
            >
              {t('steps.initial.action')}
            </Button>
          </>
        )}
      </Form>
    </div>
  );
};

export default WelcomeStep;
