import axios from 'axios';
import { BigNumber, ethers, utils } from 'ethers';
import { useState } from 'react';
import { rpcProvider } from '../imports/constants';
import { ScanResult } from '../pages/Home';

const useVerify = () => {
  const [isLoading, setIsLoading] = useState(false);
  const wallet = ethers.Wallet.createRandom();

  const verify = async (
    signature: string,
    seconds: string,
    contractId: string,
    tokenId: string,
    contractAddress: string
  ): Promise<ScanResult> => {
    try {
      if (Date.now() - parseFloat(seconds) > 15 * 1000) throw new Error('TimeElapsed');

      setIsLoading(true);
      const message = `I own the nft nr. ${tokenId} of smart contract ${contractAddress}. Time: ${seconds}`;
      const userAddress = utils.verifyMessage(message, signature);
      const provider = new ethers.providers.JsonRpcProvider(rpcProvider);
      const contract = new ethers.Contract(
        contractAddress,
        [
          {
            inputs: [
              {
                internalType: 'address',
                name: 'account',
                type: 'address',
              },
              {
                internalType: 'uint256',
                name: 'id',
                type: 'uint256',
              },
            ],
            name: 'balanceOf',
            outputs: [
              {
                internalType: 'uint256',
                name: '',
                type: 'uint256',
              },
            ],
            stateMutability: 'view',
            type: 'function',
          },
          {
            inputs: [
              {
                internalType: 'uint256',
                name: 'tokenId',
                type: 'uint256',
              },
            ],
            name: 'checkValidity',
            outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
            stateMutability: 'view',
            type: 'function',
          },
        ],
        wallet.connect(provider)
      );

      const ownedNfts = BigNumber.from(await contract.balanceOf(userAddress, tokenId)).toNumber();

      const isOwner = ownedNfts > 0;

      if (isOwner) {
        const isValid = true;

        // const docRef = doc(db, 'contracts', contractId);
        // const docSnapshot = await getDoc(docRef);
        // if (docSnapshot.data()?.expiration > 0) {
        //   isValid = await contract.checkValidity(tokenId);
        // }

        const {
          data: { value, history },
        } = await axios.post(`${process.env.REACT_APP_BACKEND_ENDPOINT}/useNft`, {
          contractId,
          tokenId,
          userAddress,
        });

        return {
          status: 'owner',
          numberOfVerifications: value,
          history,
          isValid,
        };
      }

      return {
        status: 'not-owner',
      };
    } catch (error) {
      if ((error as Error).message === 'TimeElapsed') {
        return {
          status: 'time-elapsed',
        };
      }
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return { verify, isLoading };
};

export default useVerify;
